import React from 'react';
import AboutUs from './AboutUs'; // Assuming you have this component
import Gallery from './Gallery'; // Assuming you have this component
import ServiceSection from './ServiceSection'; // Import the ServiceSection

const AboutGallerySection = () => {
  return (
    <div className="px-4 md:px-6 lg:px-8 py-0">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
        <div className="w-full md:mt-12"> {/* Same top margin for alignment */}
          <ServiceSection />
          <AboutUs />
        </div>
        <div id="gallery" className="w-full md:mt-12"> {/* Same top margin for alignment */}
          <Gallery />
        </div>
      </div>
    </div>
  );
};

export default AboutGallerySection;