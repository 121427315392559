import React from 'react';
import Slider from 'react-slick';

const Gallery = () => {
  const images = [
    '/SalonPic1.JPG',
    '/SalonPic2.JPG',
    '/SalonPic3.JPG',
    '/SalonPic4.JPG',
    '/SalonPic5.JPG'
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <div className="w-full overflow-hidden min-h-screen py-10"> {/* Full width and hide overflow */}
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="w-full h-auto"> {/* Full width, auto height */}
            <img src={image} alt={`Gallery item ${index + 1}`} className="w-full h-auto object-cover" /> {/* Full width, auto height */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Gallery;
