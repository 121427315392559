
import React, { useState, useEffect } from 'react';
import './BookButton.css'; // Import the CSS file for pulsation effect

const BookButton = () => {
  const [isInContact, setIsInContact] = useState(false);
  const bookingUrl = 'https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVFNiLgEghV9F8MtN18eSzKVEUNY01NuLL8OVXJ+7HzsXXqtePs84+NoafjLs9hVqawySCzDUGfk5wtBA+O/lpQbndzv7HEOylswQgGAS/J7gnUG8joXnEHzkFkn42/YpeAMTqM7uRtk7cht7Imsr8AT05DfHjCyeX0Bh2wj70gMKjrLSUbHe8a0/H8ZjOFtT7McMXxXbW0eykg8KcMIJkuA9MEMsFUJrwdGkaBolHHZCyzvG5g8wlfnvqtfPne4nlN0u2FNQaAK9FbLnlyUM9718KLM+pTm+Z+p54/G5x5SWyQ6MegQO7uWCb8qt/E0fUKOPY0We+2mYauzwzxqkrGc4+Igzd92XABjlbEVm/ijyb+L85VCUHDvQsdwvpB5OzC/0u9/XeAoymQQzuVwHV9o17sB3PfkdyWHI8tazjCK1OFq16SknUQQJ3wJbt9C7mw=='; // Replace with your actual booking URL

  useEffect(() => {
    const contactSection = document.getElementById('contact');

    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log('Intersection Observer Entry:', entry); // Debugging log
        setIsInContact(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (contactSection) {
      observer.observe(contactSection);
    }

    return () => {
      if (contactSection) {
        observer.unobserve(contactSection);
      }
    };
  }, []);

  return (
    <div className="fixed inset-x-0 bottom-0 px-5 pb-4 md:pb-10 md:right-10 md:left-auto bg-transparent md:bg-transparent z-50 flex justify-center md:justify-end">
      <a
        href={bookingUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={`book-button font-bold py-3 px-6 rounded-full transition duration-300 text-center w-20 h-20 flex items-center justify-center ${
          isInContact ? 'bg-white text-green-dark' : 'bg-green-dark text-white'
        }`}
      >
        Book Now
      </a>
    </div>
  );
};

export default BookButton;
