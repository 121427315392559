import React from 'react';

const AboutUs = () => {
  return (
    <section
      id="about"
      className="text-white text-left font-avenir"
      style={{ 
        backgroundImage: `url(/SalonPic4.JPG)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
      }}
    >
      <div className="flex flex-col justify-center items-center min-h-screen">
        <div className="max-w-3xl mx-auto p-8 bg-black bg-opacity-50 rounded-lg">
          <h2 className="text-4xl font-bold mb-4">About Us</h2>
          <p className="text-lg mb-4 leading-relaxed">
            Welcome to our premium nail salon, where advanced technology meets exceptional service. We offer a relaxing atmosphere and flawless results using top-quality products. From classic manicures to the latest nail art, we cater to all your needs with meticulous attention to detail.
            Experience the ultimate in nail care with us. Discover the difference in excellence and beauty.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;