import React from 'react';
const SloganSection = () => {
  const slogan = "Snails: Experience the Beauty of Perfection";

  // Ensure the slogan is 40 words or fewer
  const trimmedSlogan = slogan.split(' ').slice(0, 40).join(' ');

  const sectionStyle: React.CSSProperties = {
    backgroundImage: `url(/SalonPic5.JPG)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '50vh', // Adjust height as necessary
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white', // Adjust text color as necessary
    padding: '0 20px',
    marginTop: '10vh', // 10% of the viewport height
  };

  return (
    <section style={sectionStyle}>
      <h2 className="text-2xl md:text-5xl font-bold font-avenir-italic-bold">{trimmedSlogan}</h2>
    </section>
  );
};

export default SloganSection;