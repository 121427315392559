import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const offset = -80; // Adjust this value based on your header height

  return (
    <header className={`fixed top-0 left-0 w-full flex justify-between items-center z-10 transition duration-300 ${isScrolled ? 'bg-beige-100 shadow-md' : 'bg-transparent'}`}>
      <img src='logo.png' alt="Logo" className="h-24 md:h-24" /> {/* Adjust the width and height as needed */}
      <nav className="hidden md:flex space-x-4 lg:space-x-8">
        <Link to="about" className="text-headazz px-2 lg:px-4" smooth={true} offset={offset}>About Us</Link>
        <Link to="services" className="text-headazz-dark px-2 lg:px-4" smooth={true} offset={offset}>Services</Link>
        <Link to="gallery" className="text-headazz-dark px-2 lg:px-4" smooth={true} offset={offset}>Gallery</Link>
        <Link to="contact" className="text-headazz-dark px-2 lg:px-4" smooth={true} offset={offset}>Contact Us</Link>
      </nav>
      <div className="md:hidden">
        <button className="text-headazz-dark px-2" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
      {isMenuOpen && (
        <nav className="absolute top-16 left-0 w-full bg-beige-100 shadow-md flex flex-col items-center md:hidden">
          <Link to="about" className="text-headazz py-2" smooth={true} offset={offset} onClick={() => setIsMenuOpen(false)}>About Us</Link>
          <Link to="services" className="text-headazz-dark py-2" smooth={true} offset={offset} onClick={() => setIsMenuOpen(false)}>Services</Link>
          <Link to="gallery" className="text-headazz-dark py-2" smooth={true} offset={offset} onClick={() => setIsMenuOpen(false)}>Gallery</Link>
          <Link to="contact" className="text-headazz-dark py-2" smooth={true} offset={offset} onClick={() => setIsMenuOpen(false)}>Contact Us</Link>
        </nav>
      )}
    </header>
  );
};

export default Header;
