import React, { useState, useRef } from 'react';
import './ServiceSection.css'; // Import the CSS file
import Modal from './Modal'; // Import the Modal component

interface Service {
  name: string;
  price: string;
}

interface ServiceCategories {
  [category: string]: Service[];
}

const serviceCategories: ServiceCategories = {
  'Nails': [
    { name: "Classic, European, Smart, Combined", price: "$40" },
    { name: "Gel remove, Manicure, Gel polish", price: "$75" },
    { name: "Gel remove, Manicure, Gel polish, Light Design", price: "$85" },
    { name: "Gel remove, Manicure, Gel polish, Extra Design", price: "$95" },
    { name: "Nail Care Naked", price: "$85" },
    { name: "P-Shine Japanese", price: "$100" },
    { name: "Manicure with Voesh three-stage ritual", price: "$75" },
  ],
  'Pedicure': [
    { name: "Classic, European, Smart, Combined", price: "$60" },
    { name: "Gel remove, Pedicure, Gel polish", price: "$85" },
    { name: "Gel remove, Pedicure, Gel polish, Light Design", price: "$95" },
    { name: "Gel remove, Pedicure, Gel polish, Extra Design", price: "$105" },
    { name: "Nail Care Naked", price: "$95" },
    { name: "P-Shine Japanese", price: "$115" },
    { name: "Jelly Spa Bath", price: "$90" },
    { name: "Pedicure with Voesh four-stage ritual", price: "$100" },
  ],
  'Four hands service': [
    { name: "Manicure & Pedicure Combo", price: "$130" },
    { name: "Manicure & Pedicure Combo with Gel Polish", price: "$175" }
  ],
  'Sugaring': [
    { name: "Lips", price: "$20" },
    { name: "Chin", price: "$20" },
    { name: "Lips & Chin", price: "$35" },
    { name: "Underarms", price: "$30" },
    { name: "Full Arms", price: "$50" },
    { name: "Half Arms", price: "$45" },
    { name: "Full Legs", price: "$85" },
    { name: "Half Legs", price: "$55" },
    { name: "Chest", price: "$40" },
    { name: "Stomach", price: "$15" },
    { name: "Back", price: "$75" },
    { name: "Bikini", price: "$55" },
    { name: "Brazilian", price: "$65" },
    { name: "Full Body", price: "$230" }
  ]
};

const ServiceSection = () => {
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const contentRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleToggle = (category: string) => {
    setExpandedCategory(prevCategory => (prevCategory === category ? null : category));
  };

  const handleModalOpen = (category: string) => {
    setModalContent(
      <div>
        {serviceCategories[category].map((service, index) => (
          <div key={index} className="border-t border-green-dark pt-2 px-4 pb-4 flex justify-between">
            <h4 className="text-lg font-semibold">{service.name}</h4>
            <p className="text-lg font-bold font-avenirservice">{service.price}</p>
          </div>
        ))}
      </div>
    );
  };

  const handleModalClose = () => {
    setModalContent(null);
  };

  const isDesktop = window.innerWidth >= 1024;

  return (
    <section id="services" className="bg-beige-100 py-0 my-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {['Nails', 'Pedicure', 'Sugaring', 'Four hands service'].map((category) => (
            <div key={category} className="group">
              <div
                className="service-button bg-green-dark text-white p-4 rounded-lg shadow cursor-pointer overflow-hidden transition-all duration-500 ease flex items-center justify-center"
                onClick={() => isDesktop ? handleModalOpen(category) : handleToggle(category)}
                style={{ minHeight: '100px' }}
              >
                <h3 className="text-2xl md:text-3xl text-center font-semibold font-avenir">{category}</h3>
              </div>
              {!isDesktop && (
                <div
                  ref={el => (contentRefs.current[category] = el)}
                  className={`transition-all duration-500 ease-in-out overflow-hidden bg-white ${expandedCategory === category ? 'slide-down' : 'slide-up'}`}
                >
                  {serviceCategories[category].map((service, index) => (
                    <div key={index} className="border-t border-green-dark pt-2 px-4 pb-4 flex justify-between">
                      <h4 className="text-lg font-semibold">{service.name}</h4>
                      <p className="text-lg font-bold font-avenirservice">{service.price}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={!!modalContent} onClose={handleModalClose} title="Service Details">
        {modalContent}
      </Modal>
    </section>
  );
};

export default ServiceSection;