import React, { useState } from 'react';

const ContactForm = () => {
  const [showNumber, setShowNumber] = useState(false);

  const handleContactClick = () => {
    // Show the phone number
    setShowNumber(true);
  };

  const handleCallClick = () => {
    window.location.href = 'tel:+16105459229';
  };

  return (
    <div id="contact" className="bg-green-dark text-white py-10 px-4 lg:px-8">
      <div className="max-w-xl mx-auto text-center">
        <h2 className="text-4xl mb-4">Contact Us</h2>
        <p className="mb-4"></p>
        <address className="not-italic mb-4">
        snails.beautyplace@gmail.com <br/>
          103 S Newtown Street Rd<br />
          Newtown Square, PA 19073
        </address>

        <div className="mt-8">
          <p>Questions?</p>
          <button 
            onClick={showNumber ? handleCallClick : handleContactClick} 
            className="bg-transparent border-2 border-white py-2 px-4 rounded-full mt-4" 
          >
            {showNumber ? '610-545-9229' : 'Call Us'}
          </button>
        </div>


        <div className="flex flex-wrap justify-between items-center mt-8">
        </div>
      </div>
    </div>
  );
};

export default ContactForm;