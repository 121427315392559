// src/App.tsx
import React from 'react';

import Header from './Components/Header';
import ServiceSection from './Components/ServiceSection';
import ContactForm from './Components/ContactForm';
import Footer from './Components/Footer';
import Gallery from './Components/Gallery';
import AboutUs from './Components/AboutUs';
import AboutGallerySection from './Components/AboutUsGallery';
import BookButton from './Components/BookButton';
import SloganSection from './Components/Slogan';
import ContactUs from './Components/ContactForm';




const App: React.FC = () => {
  return (
    <div>
      <Header />
      <main>
        <SloganSection></SloganSection>
      <AboutGallerySection/>
        <ContactForm />
        <BookButton></BookButton>
      </main>
    </div>
  );
};

export default App;